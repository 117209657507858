import store from "@/store";
export const network = {
  data: function data() {
    return {
      isOnline: false,
      isOffline: false,
    };
  },

  mounted: function mounted() {
    var _this = this;

    if (typeof window !== "undefined") {
      navigator.onLine ? (this.isOnline = true) : (this.isOffline = true);

      var onlineHandler = function onlineHandler() {
        _this.$emit("online");

        store.dispatch("sync/syncOrders");
        _this.isOnline = true;
        _this.isOffline = false;
      };

      var offlineHandler = function offlineHandler() {
        _this.$emit("offline");

        _this.isOffline = true;
        _this.isOnline = false;
      };

      window.addEventListener("online", onlineHandler);
      window.addEventListener("offline", offlineHandler);
      this.$once("hook:beforeDestroy", function () {
        window.removeEventListener("online", onlineHandler);
        window.removeEventListener("offline", offlineHandler);
      });
    }
  },
};
